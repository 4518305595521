import logo from './assets/IIIT.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';

import ReactTooltip from 'react-tooltip';
import { Card, Navbar, Nav } from 'react-bootstrap';
import { BrowserRouter as Router, Redirect, Route, Link } from "react-router-dom";

import 'react-reflex/styles.css'
import "./custom.css";

import home from './components/home';
import metadata from './components/metadata';
import results from './components/results';

const utf8 = require('utf8');
class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      input: ''
    }

    this.onChangeInput = this.onChangeInput.bind(this);

  }

  onChangeInput(event) {
    this.setState({ input: encodeURI(event.target.value) });
    console.log(this.state.input)
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
            <Nav.Link><Link to="/" className="nav-link"><img src={logo} alt="IIIT Logo" className="photo" /></Link></Nav.Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link><Link to="/papers" className="nav-link" data-tip="Search the research papers by title or query using filters (author, journal, country)"><b>Research Papers</b></Link></Nav.Link>
                <Nav.Link><Link to="/results" className="nav-link" data-tip="Query and summarize the results section of papers using filters (disease, drug, gene, miRNA, mutation, pathway, species, etc)"><b>Mined Result Sections of Research Papers</b></Link></Nav.Link>
                {/* <Nav.Link><Link to="/papers?size=n_20_n&sort%5B0%5D%5Bfield%5D=metaid&sort%5B0%5D%5Bdirection%5D=asc" className="nav-link" data-tip="Search papers by title or query using metadata filters (author, journal, country)">Query Papers</Link></Nav.Link> */}
                {/* <Nav.Link><Link to="/results?size=n_20_n&sort%5B0%5D%5Bfield%5D=metaid&sort%5B0%5D%5Bdirection%5D=asc" className="nav-link" data-tip="Query papers using result filters (disease, drug, name)">Query Results</Link></Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
            <Navbar.Brand><Link to="/" className="nav-link">ICDW - IIIT Hyderabad</Link></Navbar.Brand>
          </Navbar>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/home" component={home} />
          <Route path="/papers" component={metadata} />
          <Route path="/results" component={results} />
        </div>
        <ReactTooltip place="bottom" type="dark" effect="solid" />
      </Router>
    );
  }
}


export default App;
