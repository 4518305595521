import React, { useState } from "react";
import {Helmet} from "react-helmet";
import ElasticsearchAPIConnector from "@elastic/search-ui-elasticsearch-connector";
import "../feedback.css";
import {
    ErrorBoundary,
    Facet,
    SearchProvider,
    SearchBox,
    Results,
    PagingInfo,
    ResultsPerPage,
    Paging,
    Sorting,
    WithSearch
} from "@elastic/react-search-ui";

import { Layout, MultiCheckboxFacet } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import ClearFilters from './ClearFilters';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import Button from '@material-ui/core/Button';
import axios from 'axios';

var Rating = require('react-rating');
const connector = new ElasticsearchAPIConnector({
    host: "",
    index: "metadata_index_without_duplicates"
});

const metadataconfig = {
    searchQuery: {
        search_fields: {
            title: { weight: 3 },
            authors: { weight: 2},
            journal: { weight: 2},
            disease: { weight: 2},
            drug: { weight: 2},
            gene: { weight: 2},
            mirna: { weight: 2},
            mutation: { weight: 2},
            pathway: { weight: 2},
            species: { weight: 2},
            abstract: {weight: 1},
        },
        result_fields: {
            metaid: {},
            title: {
                snippet: {
                    fallback: true
                }
            },
            abstract: {
                snippet: {
                    fallback: true
                }
            },
            authors: {
                raw: {}
            },
            license: {
                snippet: {
                    fallback: true
                }
            },
            journal: {
                snippet: {
                    fallback: true
                }
            },
            url: {
                raw: {}
            },
            publish_time: {
                snippet: {
                    fallback: true
                }
            },
            similarpaper: {
                raw: {}
            },
            disease: {
                snippet: {
                    fallback: true
                }
            },
            drug: {
                snippet: {
                    fallback: true
                }
            },
            gene: {
                snippet: {
                    fallback: true
                }
            },
            mirna: {
                snippet: {
                    fallback: true
                }
            },
            mutation: {
                snippet: {
                    fallback: true
                }
            },
            papertype: {
                snippet: {
                    fallback: true
                }
            },
            pathway: {
                snippet: {
                    fallback: true
                }
            },
            species: {
                snippet: {
                    fallback: true
                }
            }
        },
        // autocompleteQuery: {
        //     results: {
        //         resultsPerPage: 5,
        //         search_fields: {
        //             "title.suggest": {
        //                 weight: 3
        //             }
        //         },
        //         result_fields: {
        //             title: {
        //                 snippet: {
        //                     size: 100,
        //                     fallback: true
        //                 }
        //             }
        //         }
        //     },
        //     suggestions: {
        //         types: {
        //             results: { fields: ["title"] }
        //         },
        //         size: 4
        //     }
        // },
        disjunctiveFacets: ["journal.keyword", "metaid", "disease.keyword", "drug.keyword", "gene.keyword", "mirna.keyword", "mutation.keyword", "pathway.keyword", "species.keyword"],
        facets: {
            "authors.keyword": { type: "value" },
            "journal.keyword": { type: "value" },
            "disease.keyword": { type: "value" },
            "drug.keyword": { type: "value" },
            "gene.keyword": { type: "value" },
            "mirna.keyword": { type: "value" },
            "mutation.keyword": { type: "value" },
            "pathway.keyword": { type: "value" },
            "species.keyword": { type: "value" },
            "metaid": { type: "value" }
        }
    },
    apiConnector: connector,
    alwaysSearchOnInitialLoad: true
};

const SORT_OPTIONS = [
    {
        name: "Relevance",
        value: "",
        direction: ""
    },
    {
        name: "Publish date (oldest first)",
        value: [
            {
                field: "publish_time",
                direction: "asc"
            }
        ]
    },
    {
        name: "Publish date (latest first)",
        value: [
            {
                field: "publish_time",
                direction: "desc"
            }
        ]
    }
];

function formatAuthors(authors) {
    for (let i = 0; i < authors.length; i++) {
        authors[i] = "<a href=\"http://icdw.iiit.ac.in/papers?size=n_20_n&filters%5B0%5D%5Bfield%5D=authors.keyword&filters%5B0%5D%5Bvalues%5D%5B0%5D=" + encodeURI(authors[i]) + "&filters%5B0%5D%5Btype%5D=all\"} target=\"_blank\"> " + authors[i] + "</a>"
    }
    return authors
}


const CustomResultView = ({
    result,
    onClickLink,
}) => {
    let feedback = React.createRef();

    const [showModal, setShowModal] = useState(true);
    const [showThumbs, setShowThumbs] = useState(true);

    function onLikeClick() {
        let body = {
            "metaid": result.metaid.raw,
            "likes": 1
        };

        axios.post("/paperfeedback", body, {}).then(() => console.log('Feedback submitted'))
            .catch(err => {
                console.error(err);
            });
        setShowThumbs(false);
    }

    function onUnlikeClick() {
        let body = {
            "metaid": result.metaid.raw,
            "likes": -1
        };

        axios.post("/paperfeedback", body, {}).then(() => console.log('Feedback submitted'))
            .catch(err => {
                console.error(err);
            });
        setShowThumbs(false);
    }

    const handleClick = e => {
        e.preventDefault();
        alert("Feedback submitted for paper ID " + result.metaid.raw);
        let body = {
            "metaid": result.metaid.raw,
            "feedback": feedback.current.value
        };

        axios.post("/paperfeedback", body, {}).then(() => console.log('Feedback submitted'))
            .catch(err => {
                console.error(err);
            });

        setShowModal(false);
    }
    
    function formatSimilarResults() {
        let resultHTML = ""

        if (result.disease.raw.length != 0 || result.drug.raw.length != 0 || result.gene.raw.length != 0 || result.mirna.raw.length != 0 || result.mutation.raw.length != 0 || result.pathway.raw.length != 0 || result.species.raw.length != 0)
        {   
            resultHTML = "<a href=\"http://icdw.iiit.ac.in/results?size=n_20_n&filters%5B0%5D%5Bfield%5D=metaid&filters%5B0%5D%5Bvalues%5D%5B0%5D=" + result.metaid.raw + "&filters%5B0%5D%5Btype%5D=all\" target=\"_blank\">View results for this paper</a><br>"
        }

        if (result.similarpaper.raw.length !=0)
        {
            resultHTML += "<a href=\"http://icdw.iiit.ac.in/results?size=n_20_n&filters%5B0%5D%5Bfield%5D=metaid"            
            for (let i = 0; i < result.similarpaper.raw.length; i++) {
                resultHTML += "&filters%5B0%5D%5Bvalues%5D%5B" + (i + 1) + "%5D=" + result.similarpaper.raw[i]
            }
            resultHTML += "&filters%5B0%5D%5Btype%5D=all\"} target=\"_blank\"> " + "View results for similar papers" + "</a>"
        }

        return resultHTML
    }

    return (
        <li className="sui-result">
            <div className="sui-result__header">
                <span class="sui-result__title">
                    {/* Maintain onClickLink to correct track click throughs for analytics*/}
                    <a onClick={onClickLink} href={result.url.raw} target="_blank">
                        {result.title.raw}
                    </a>
                </span>
            </div>
            <div className="sui-result__body">
                {/* use 'raw' values of fields to access values without snippets */}
                {/* Use the 'snippet' property of fields with dangerouslySetInnerHtml to render snippets */}
                <div
                    className="sui-result__details"
                    dangerouslySetInnerHTML={{
                        __html:
                            (result.authors.raw.length != 0 ? "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Authors: </span>" +
                            "<span class=\"sui-result__value\">" + formatAuthors(result.authors.raw) + "</span>" +
                            "</li>" : "") +

                            (result.journal.raw.length != 0 ? "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Journal: </span>" +
                            "<span class=\"sui-result__value\">" + "<a href=\"http://icdw.iiit.ac.in/papers?size=n_20_n&filters%5B0%5D%5Bfield%5D=journal.keyword&filters%5B0%5D%5Bvalues%5D%5B0%5D=" + encodeURI(result.journal.raw) + "&filters%5B0%5D%5Btype%5D=all\" target=\"_blank\"> " + result.journal.raw + "</a>" + "</span>" +
                            "</li>" : "")+

                            (result.disease.raw.length != 0 ? "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Diseases: </span>" +
                            "<span class=\"sui-result__value\">" + (result.disease.snippet ? result.disease.snippet : result.disease.raw) + "</span>" +
                            "</li>" : "") +

                            (result.drug.raw.length != 0 ? "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Drugs: </span>" +
                            "<span class=\"sui-result__value\">" + (result.drug.snippet ? result.drug.snippet : result.drug.raw) + "</span>" +
                            "</li>" : "") +

                            (result.gene.raw.length != 0 ? "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Genes: </span>" +
                            "<span class=\"sui-result__value\">" + (result.gene.snippet ? result.gene.snippet : result.gene.raw) + "</span>" +
                            "</li>" : "") +

                            (result.mirna.raw.length != 0 ? "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> MiRNAs: </span>" +
                            "<span class=\"sui-result__value\">" + (result.mirna.snippet ? result.mirna.snippet : result.mirna.raw) + "</span>" +
                            "</li>" : "") +

                            (result.mutation.raw.length != 0 ? "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Mutations: </span>" +
                            "<span class=\"sui-result__value\">" + (result.mutation.snippet ? result.mutation.snippet : result.mutation.raw) + "</span>" +
                            "</li>" : "") +

                            (result.pathway.raw.length != 0 ? "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Pathway: </span>" +
                            "<span class=\"sui-result__value\">" + (result.pathway.snippet ? result.pathway.snippet : result.pathway.raw) + "</span>" +
                            "</li>" : "") +

                            (result.species.raw.length != 0 ? "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Species: </span>" +
                            "<span class=\"sui-result__value\">" + (result.species.snippet ? result.species.snippet : result.species.raw) + "</span>" +
                            "</li>" : "") +

                            (result.publish_time.raw != "" ? "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Publish date (yyyy-MM-dd): </span>" +
                            "<span class=\"sui-result__value\">" + result.publish_time.raw + "</span>" +
                            "</li>": "") +

                            "<li>" +
                            "<span class=\"sui-result__value\">" + formatSimilarResults() + "</span>" +
                            "</li>" +

                            ((result.license.raw != "" || result.license.raw == "unk") ? "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> License: </span>" +
                            "<span class=\"sui-result__value\">" + (result.license.snippet ? result.license.snippet : result.license.raw) + "</span>" +
                            "</li>" : "") +

                            (result.abstract.raw != "" ? "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Abstract: </span>" +
                            "<span class=\"sui-result__value\">" + (result.abstract.snippet ? result.abstract.snippet : result.abstract.raw) + "</span>" +
                            "</li>" : "") +

                            // "<li>" +
                            // "<span class=\"sui-result__value\" style = \"color:#777777\"> Paper ID: </span>" +
                            // "<span class=\"sui-result__value\">" + (result.metaid.raw ? result.metaid.raw : "N/A") + "</span>" +
                            // "</li>" +

                            "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Title: </span>" +
                            "<span class=\"sui-result__value\">" + (result.title.snippet ? result.title.snippet : (result.title.raw ? result.title.raw : "N/A")) + "</span>" +
                            "</li>"
                    }}
                >
                </div>
            </div>
            <div className="sui-result__image">
                {showThumbs ? <Button onClick={onLikeClick}><ThumbUpIcon /></Button> : null}
                {showThumbs ? <Button onClick={onUnlikeClick}><ThumbDownIcon /></Button> : null}
                {showModal && <Popup className="feedbackpop" trigger={<Button>Feedback</Button>} id="feedbackModal" modal>
                    <form>
                        <span>
                            <div className="header">
                                <span>
                                    <h2> Feedback for paper</h2>
                                </span>
                            </div>
                            <span class="sui-result__value">
                                <b> Paper ID: {result.metaid.raw}</b><br></br>
                                <b> Paper title: {result.title.raw}</b><br></br>
                                {/* <br></br>How relevant were the results on a scale of 1 to 5? &nbsp;
                                <div onChange={onChangeValue}>
                                    <input type="radio" id="choice1" name="relevant" value="1"></input>
                                    <label for="choice1"> &nbsp;1&nbsp;</label>
                                    <input type="radio" id="choice2" name="relevant" value="2"></input>
                                    <label for="choice2"> &nbsp;2&nbsp;</label>
                                    <input type="radio" id="choice3" name="relevant" value="3"></input>
                                    <label for="choice3"> &nbsp;3&nbsp;</label>
                                    <input type="radio" id="choice4" name="relevant" value="4"></input>
                                    <label for="choice4"> &nbsp;4&nbsp;</label>
                                    <input type="radio" id="choice5" name="relevant" value="5"></input>
                                    <label for="choice5"> &nbsp;5&nbsp;</label>
                                </div> */}
                            </span>
                            <span class="sui-result__value">
                                <br></br> Please enter feedback: <input class="sui-facet-search__text-input" type="text" ref={feedback} maxlength="1000"></input>
                            </span>
                            <br></br>
                            <br></br>
                            <Button class="sui-search-box__submit" type="submit" id="feedback" onClick={handleClick}>Submit</Button>
                            {/* <Button><a href={"feedbacklink " + result.metaid.raw}>Feedback</a></Button> */}
                        </span>
                    </form>
                </Popup>}
            </div>
        </li>
    );
};

const metadata = () => {
    return (
        <SearchProvider config={metadataconfig}>
            <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
                {({ wasSearched }) => {
                    return (
                        <div className="Metadata">
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>Research Papers | ICDW</title>
                            </Helmet>
                            <ErrorBoundary>
                                <Layout
                                    header={
                                        <SearchBox
                                            searchAsYouType={true}
                                            inputProps={{type: "Search", placeholder: "Search by paper title/abstract or search for authors/journal/disease/drug/gene/miRNA/mutation/pathway/species"}}
                                            // autocompleteResults={{
                                            //     linkTarget: "_blank",
                                            //     sectionTitle: "Results",
                                            //     titleField: "title",
                                            //     shouldTrackClickThrough: true
                                            // }}
                                            // autocompleteSuggestions={true}
                                            // autocompleteMinimumCharacters={3}
                                            debounceLength={0}
                                        />
                                    }
                                    sideContent={
                                        <div>
                                            <ClearFilters />
                                            <br />
                                            <br />
                                            {wasSearched && <Sorting label={"Sort by"} sortOptions={SORT_OPTIONS} />}
                                            <br/>
                                            <br/>
                                            The following filters are available for all the papers.
                                            <br/>
                                            <br/>
                                            
                                            <Facet field={"authors.keyword"} label={"authors"} view={MultiCheckboxFacet} />
                                            <Facet field={"journal.keyword"} label={"journal"} view={MultiCheckboxFacet} />
                                            <br/>
                                            The following filters are available for only 20% of the papers and might not always show.
                                            <br/>
                                            <br/>

                                            <Facet field={"disease.keyword"} label={"disease"} />
                                            <Facet field={"drug.keyword"} label={"drug"} />
                                            <Facet field={"gene.keyword"} label={"gene"} />
                                            <Facet field={"mirna.keyword"} label={"mirna"} />
                                            <Facet field={"mutation.keyword"} label={"mutation"} />
                                            <Facet field={"pathway.keyword"} label={"pathway"} />
                                            <Facet field={"species.keyword"} label={"species"} />
                                        </div>
                                    }
                                    bodyContent={<Results
                                        resultView={CustomResultView}
                                        titleField={"title"} urlField={"url"} shouldTrackClickThrough={true} />}
                                    bodyHeader={
                                        <React.Fragment>
                                            {wasSearched && <PagingInfo />}
                                            {wasSearched && <ResultsPerPage />}
                                        </React.Fragment>
                                    }
                                    bodyFooter={<Paging />}
                                />
                            </ErrorBoundary>
                        </div>
                    );
                }}
            </WithSearch>
        </SearchProvider>
    );
};

export default metadata;
