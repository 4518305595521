import React, { useState, useRef } from "react";
import {Helmet} from "react-helmet";

import ElasticsearchAPIConnector from "@elastic/search-ui-elasticsearch-connector";
import ReactTooltip from 'react-tooltip';
import Popup from 'reactjs-popup';
import axios from 'axios';

import parse from 'html-react-parser';

import {
    ErrorBoundary,
    Facet,
    SearchProvider,
    SearchBox,
    Results,
    PagingInfo,
    ResultsPerPage,
    Paging,
    Sorting,
    WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import ClearFilters from './ClearFilters';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import HelpIcon from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';

const connector = new ElasticsearchAPIConnector({
    //host: "http://icdw.iiit.ac.in:3001",
    host: "",
    index: "result_without_duplicates"
});

const resultsconfig = {
    searchQuery: {
        filters: [{
            field: "papertype.keyword", values: ["medical"]
        }],
        search_fields: {
            title: {weight: 2},
            disease: {},
            drug: {},
            gene: {},
            mirna: {},
            mutation: {},
            pathway: {},
            species: {},
            authors: {},
            journal: {}
        },
        result_fields: {
            metaid: {},
            title: {
                snippet: {
                    fallback: true
                }
            },
            url: {
                raw: {}
            },
            disease: {
                snippet: {
                    fallback: true
                }
            },
            drug: {
                snippet: {
                    fallback: true
                }
            },
            gene: {
                snippet: {
                    fallback: true
                }
            },
            mirna: {
                snippet: {
                    fallback: true
                }
            },
            mutation: {
                snippet: {
                    fallback: true
                }
            },
            papertype: {
                snippet: {
                    fallback: true
                }
            },
            pathway: {
                snippet: {
                    fallback: true
                }
            },
            species: {
                snippet: {
                    fallback: true
                }
            },
            authors: {
                raw: {}
            },
            journal: {
                snippet: {
                    fallback: true
                }
            },
            publish_time: {
                snippet: {
                    fallback: true
                }
            },
            onerankings: {},
            tworankings: {},
            threerankings: {},
            fourrankings: {},
            fiverankings: {},
            sixrankings: {},
            sentences: {},
            similarpaper: {}
        },
        disjunctiveFacets: ["hassummary.keyword", "journal.keyword", "papertype.keyword", "disease.keyword", "drug.keyword", "gene.keyword", "mirna.keyword", "mutation.keyword", "pathway.keyword", "species.keyword", "metaid"],
        facets: {
            "hassummary.keyword": { type: "value" },
            "papertype.keyword": { type: "value" },
            "disease.keyword": { type: "value" },
            "drug.keyword": { type: "value" },
            "gene.keyword": { type: "value" },
            "mirna.keyword": { type: "value" },
            "mutation.keyword": { type: "value" },
            "pathway.keyword": { type: "value" },
            "species.keyword": { type: "value" },
            "authors.keyword": { type: "value" },
            "journal.keyword": { type: "value" },
            "metaid": { type: "value" }
        }
    },
    autocompleteQuery: {
        results: {
            resultsPerPage: 5,
            search_fields: {
                "title.suggest": {
                    weight: 3
                }
            },
            result_fields: {
                title: {
                    snippet: {
                        size: 100,
                        fallback: true
                    }
                },
                url: {
                    raw: {}
                }
            }
        }
    },
    apiConnector: connector,
    alwaysSearchOnInitialLoad: true
};

const SORT_OPTIONS = [
    {
        name: "Relevance",
        value: "",
        direction: ""
    },
    {
        name: "Publish date (oldest first)",
        value: [
            {
                field: "publish_time",
                direction: "asc"
            }
        ]
    },
    {
        name: "Publish date (latest first)",
        value: [
            {
                field: "publish_time",
                direction: "desc"
            }
        ]
    }
];

function formatSentences(rankings, percentage, sentences) {
    if ((rankings == null) || (percentage == null) || (sentences == null))
        return null;

    var newlen = Math.ceil(percentage * rankings.length / 100)
    // console.log(rankings.slice(0, newlen))
    let sortedIndices = rankings.slice(0, newlen).sort(function (a, b) { return a - b })
    let arrangedSentences = sortedIndices.map(i => sentences[i]).join(" ")
    // let arrangedSentencesArr = arrangedSentences.split(" ")
    // // console.log(arrangedSentencesArr)
    // let formattedSentences = ""
    // for (let i = 0; i < arrangedSentencesArr.length; i++) {
    //     formattedSentences += "<span data-tip=\"Test" + i + "\">" + arrangedSentencesArr[i] + "</span>&nbsp;";
    // }
    return arrangedSentences
}

function formatAuthors(authors) {
    for (let i = 0; i < authors.length; i++) {
        authors[i] = "<a href=\"http://icdw.iiit.ac.in/results?size=n_20_n&filters%5B0%5D%5Bfield%5D=authors.keyword&filters%5B0%5D%5Bvalues%5D%5B0%5D=" + encodeURI(authors[i]) + "&filters%5B0%5D%5Btype%5D=all\"} target=\"_blank\"> " + authors[i] + "</a>"
    }
    return authors
}

var summaryNameDict = {
    1: "Summary generation using Title and Abstract",
    2: "Summary using Entity Frequency in Result",
    3: "Summary using Entity Frequency in Result, Title and Abstract",
    4: "Summary using summation of Entity similarity score within Result",
    5: "Summary using summation of Entity similarity score across Result, Title and Abstract",
    6: "Ensemble",
};

const CustomResultView = ({
    result,
    onClickLink
}) => {
    let summaryRelevance = 0;
    let accuracy = 0;
    let feedback = React.createRef();

    const [showModal, setShowModal] = useState(true);
    const [showThumbs, setShowThumbs] = useState(true);

    function onLikeClick() {
        let body = {
            "metaid": result.metaid.raw,
            "likes": 1,
            "summaryType": parseInt(chosenRankingsIndex),
            "summarySize": parseInt(chosenPercentage),
            "summaryRelevance": parseInt(summaryRelevance),
        };

        axios.post("/resultfeedback", body, {}).then(() => console.log('Feedback submitted'))
            .catch(err => {
                console.error(err);
            });
        setShowThumbs(false);
    }

    function onUnlikeClick() {
        let body = {
            "metaid": result.metaid.raw,
            "likes": -1,
            "summaryType": parseInt(chosenRankingsIndex),
            "summarySize": parseInt(chosenPercentage),
            "summaryRelevance": parseInt(summaryRelevance),
        };

        axios.post("/resultfeedback", body, {}).then(() => console.log('Feedback submitted'))
            .catch(err => {
                console.error(err);
            });
        setShowThumbs(false);
    }

    const handleClick = e => {
        alert("Feedback submitted for paper ID " + result.metaid.raw);
        let body = {
            "metaid": result.metaid.raw,
            "summaryType": parseInt(chosenRankingsIndex),
            "summarySize": parseInt(chosenPercentage),
            "summaryRelevance": parseInt(summaryRelevance),
            "accuracy": parseInt(accuracy),
            "feedback": feedback.current.value
        };

        axios.post("/resultfeedback", body, {}).then(() => console.log('Feedback submitted'))
            .catch(err => {
                console.error(err);
            });

        setShowModal(false);
    }

    function onChangeSummaryRelevanceValue(event) {
        summaryRelevance = event.target.value;
    }

    function onChangeAccuracyValue(event) {
        accuracy = event.target.value;
    }

    const [chosenRankingsIndex, setChosenRankingsIndex] = useState(1);
    const [chosenRankings, setChosenRankings] = useState(result.onerankings.raw);
    const [chosenPercentage, setChosenPercentage] = useState(25);
    // console.log(result.metaid.raw, chosenRankings, chosenPercentage)

    function updateChosenRankingsAndPercentage(rankingsIndex, percentage) {
        setShowThumbs(true)
        setShowModal(true)
        setChosenRankingsIndex(rankingsIndex)
        if (rankingsIndex == 1)
            setChosenRankings(result.onerankings.raw)
        else if (rankingsIndex == 2)
            setChosenRankings(result.tworankings.raw)
        else if (rankingsIndex == 3)
            setChosenRankings(result.threerankings.raw)
        else if (rankingsIndex == 4)
            setChosenRankings(result.fourrankings.raw)
        else if (rankingsIndex == 5)
            setChosenRankings(result.fiverankings.raw)
        else if (rankingsIndex == 6)
            setChosenRankings(result.sixrankings.raw)
        setChosenPercentage(percentage)
    }

    function formatSimilarResults() {
        let resultHTML = ""

        if (result.similarpaper.raw.length != 0)
        {
            resultHTML += "<a href=\"http://icdw.iiit.ac.in/results?size=n_20_n&filters%5B0%5D%5Bfield%5D=metaid"            
            for (let i = 0; i < result.similarpaper.raw.length; i++) {
                resultHTML += "&filters%5B0%5D%5Bvalues%5D%5B" + (i + 1) + "%5D=" + result.similarpaper.raw[i]
            }
            resultHTML += "&filters%5B0%5D%5Btype%5D=all\"} target=\"_blank\"> " + "View results for similar papers" + "</a>"
        }

        return parse(resultHTML)
    }

    return (
        <li className="sui-result">
            <div className="sui-result__header">
                <span class="sui-result__title">
                    {/* Maintain onClickLink to correct track click throughs for analytics*/}
                    <a onClick={onClickLink} href={result.url.raw} target="_blank">
                        {result.title.raw}
                    </a>
                </span>
            </div>
            <div className="sui-result__body">
                {/* use 'raw' values of fields to access values without snippets */}
                {/* Use the 'snippet' property of fields with dangerouslySetInnerHtml to render snippets */}
                <div
                    className="sui-result__details"
                    dangerouslySetInnerHTML={{
                        __html:
                            "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Authors: </span>" +
                            "<span class=\"sui-result__value\">" + (result.authors.raw.length ? formatAuthors(result.authors.raw) : "N/A") + "</span>" +
                            "</li>" +

                            "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Journal: </span>" +
                            "<span class=\"sui-result__value\">" + (result.journal.raw.length ? "<a href=\"http://icdw.iiit.ac.in/results?size=n_20_n&filters%5B0%5D%5Bfield%5D=journal.keyword&filters%5B0%5D%5Bvalues%5D%5B0%5D=" + encodeURI(result.journal.raw) + "&filters%5B0%5D%5Btype%5D=all\" target=\"_blank\"> " + result.journal.raw + "</a>" : "N/A") + "</span>" +
                            "</li>" +

                            "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Publish date (yyyy-MM-dd): </span>" +
                            "<span class=\"sui-result__value\">" + (result.publish_time.raw ? result.publish_time.raw : "N/A") + "</span>" +
                            "</li>" +

                            // "<li>" +
                            // "<span class=\"sui-result__value\" style = \"color:#777777\"> Paper ID: </span>" +
                            // "<span class=\"sui-result__value\">" + (result.metaid.raw ? result.metaid.raw : "-") + "</span>" +
                            // "</li>" +

                            "<li>" +
                            "<span class=\"sui-result__value\" style = \"color:#777777\"> Title: </span>" +
                            "<span class=\"sui-result__value\">" + (result.title.snippet ? result.title.snippet : (result.title.raw ? result.title.raw : "-")) + "</span>" +
                            "</li>" +

                            (result.disease.raw.length != 0 ? "<li>" +
                                "<span class=\"sui-result__value\" style = \"color:#777777\"> Diseases: </span>" +
                                "<span class=\"sui-result__value\">" + (result.disease.snippet ? result.disease.snippet : result.disease.raw) + "</span>" +
                                "</li>" : "") +

                            (result.drug.raw.length != 0 ? "<li>" +
                                "<span class=\"sui-result__value\" style = \"color:#777777\"> Drugs: </span>" +
                                "<span class=\"sui-result__value\">" + (result.drug.snippet ? result.drug.snippet : result.drug.raw) + "</span>" +
                                "</li>" : "") +

                            (result.gene.raw.length != 0 ? "<li>" +
                                "<span class=\"sui-result__value\" style = \"color:#777777\"> Genes: </span>" +
                                "<span class=\"sui-result__value\">" + (result.gene.snippet ? result.gene.snippet : result.gene.raw) + "</span>" +
                                "</li>" : "") +

                            (result.mirna.raw.length != 0 ? "<li>" +
                                "<span class=\"sui-result__value\" style = \"color:#777777\"> MiRNAs: </span>" +
                                "<span class=\"sui-result__value\">" + (result.mirna.snippet ? result.mirna.snippet : result.mirna.raw) + "</span>" +
                                "</li>" : "") +

                            (result.mutation.raw.length != 0 ? "<li>" +
                                "<span class=\"sui-result__value\" style = \"color:#777777\"> Mutations: </span>" +
                                "<span class=\"sui-result__value\">" + (result.mutation.snippet ? result.mutation.snippet : result.mutation.raw) + "</span>" +
                                "</li>" : "") +

                            (result.pathway.raw.length != 0 ? "<li>" +
                                "<span class=\"sui-result__value\" style = \"color:#777777\"> Pathway: </span>" +
                                "<span class=\"sui-result__value\">" + (result.pathway.snippet ? result.pathway.snippet : result.pathway.raw) + "</span>" +
                                "</li>" : "") +

                            (result.species.raw.length != 0 ? "<li>" +
                                "<span class=\"sui-result__value\" style = \"color:#777777\"> Species: </span>" +
                                "<span class=\"sui-result__value\">" + (result.species.snippet ? result.species.snippet : result.species.raw) + "</span>" +
                                "</li>" : "")
                    }}
                >
                </div>
            </div>
            {(result.onerankings.raw || result.tworankings.raw || result.threerankings.raw || result.fourrankings.raw || result.fiverankings.raw || result.sixrankings.raw) && (result.sentences) &&
                <div className="sui-result__details">
                    <div className="sui-result__value" onChange={(event) => updateChosenRankingsAndPercentage(event.target.value, chosenPercentage)}>
                        <span class="sui-result__value" style={{ color: '#777777' }}>
                            Summary type:&nbsp;
                        </span>
                        <HelpIcon data-tip="Method of summary generation used"/>
                        <br></br>
                        <input type="radio" id={result.metaid.raw + "typechoice1"} name={result.metaid.raw + "type"} value="1" defaultChecked={true}></input>
                        <label for={result.metaid.raw + "typechoice1"}> &nbsp;{summaryNameDict[1]}&nbsp;</label><br></br>
                        <input type="radio" id={result.metaid.raw + "typechoice2"} name={result.metaid.raw + "type"} value="2"></input>
                        <label for={result.metaid.raw + "typechoice2"}> &nbsp;{summaryNameDict[2]}&nbsp;</label><br></br>
                        <input type="radio" id={result.metaid.raw + "typechoice3"} name={result.metaid.raw + "type"} value="3"></input>
                        <label for={result.metaid.raw + "typechoice3"}> &nbsp;{summaryNameDict[3]}&nbsp;</label><br></br>
                        <input type="radio" id={result.metaid.raw + "typechoice4"} name={result.metaid.raw + "type"} value="4"></input>
                        <label for={result.metaid.raw + "typechoice4"}> &nbsp;{summaryNameDict[4]}&nbsp;</label><br></br>
                        <input type="radio" id={result.metaid.raw + "typechoice5"} name={result.metaid.raw + "type"} value="5"></input>
                        <label for={result.metaid.raw + "typechoice5"}> &nbsp;{summaryNameDict[5]}&nbsp;</label><br></br>
                        <input type="radio" id={result.metaid.raw + "typechoice6"} name={result.metaid.raw + "type"} value="6"></input>
                        <label for={result.metaid.raw + "typechoice6"}> &nbsp;{summaryNameDict[6]}&nbsp;</label><br></br>
                    </div>
                    <div className="sui-result__value" onChange={(event) => updateChosenRankingsAndPercentage(chosenRankingsIndex, event.target.value)}>
                    <span class="sui-result__value" style={{ color: '#777777' }}>
                            Summary size:&nbsp;
                        </span>
                        <HelpIcon data-tip="Percentage of summary to be displayed"/>
                        <br></br>
                        <input type="radio" id={result.metaid.raw + "sizechoice1"} name={result.metaid.raw + "size"} value="25" defaultChecked={true}></input>
                        <label for={result.metaid.raw + "sizechoice1"}> &nbsp;25%&nbsp;</label>
                        <input type="radio" id={result.metaid.raw + "sizechoice2"} name={result.metaid.raw + "size"} value="50"></input>
                        <label for={result.metaid.raw + "sizechoice2"}> &nbsp;50%&nbsp;</label>
                        <input type="radio" id={result.metaid.raw + "sizechoice3"} name={result.metaid.raw + "size"} value="75"></input>
                        <label for={result.metaid.raw + "sizechoice3"}> &nbsp;75%&nbsp;</label>
                        <input type="radio" id={result.metaid.raw + "sizechoice4"} name={result.metaid.raw + "size"} value="100"></input>
                        <label for={result.metaid.raw + "sizechoice4"}> &nbsp;100%&nbsp;</label>
                    </div>
                    <span class="sui-result__value">
                        <span style={{ color: '#777777' }}>
                            Summary:
                        </span><br></br>
                        {formatSentences(chosenRankings, chosenPercentage, result.sentences.raw)}
                        <br></br>
                        <span class="sui-result__value"> {formatSimilarResults()} </span>
                        
                    </span>
                </div>}
            <div className="sui-result__image">
                {showThumbs ? <Button onClick={onLikeClick}><ThumbUpIcon /></Button> : null}
                {showThumbs ? <Button onClick={onUnlikeClick}><ThumbDownIcon /></Button> : null}
                {showModal && <Popup className="feedbackpop" trigger={<Button>Feedback</Button>} modal>
                    <form>
                        <span>
                            <div className="header">
                                <span>
                                    <h2> Feedback for results of paper </h2>
                                </span>
                            </div>
                            {/* <input type="text" disabled={useState(true)} defaultValue={result.metaid.raw} /> */}
                            <span class="sui-result__value">
                                <b> Paper ID: {result.metaid.raw}</b><br></br>
                                <b> Paper title: {result.title.raw}</b><br></br>
                            </span>
                            {(result.onerankings.raw || result.tworankings.raw || result.threerankings.raw || result.fourrankings.raw || result.fiverankings.raw || result.sixrankings.raw) && (result.sentences) &&
                                <div onChange={onChangeSummaryRelevanceValue}>
                                    <span class="sui-result__value">
                                        <b> Chosen summary: {summaryNameDict[chosenRankingsIndex]}</b><br></br>
                                        <b> Chosen percentage: {chosenPercentage}%</b><br></br>
                                        <br></br>How relevant is the chosen result summary on a scale of 1 to 5 (5 being the best)? &nbsp;
                                        <input type="radio" id="summarychoice1" name="relevantSummary" value="1"></input>
                                        <label for="summarychoice1"> &nbsp;1&nbsp;</label>
                                        <input type="radio" id="summarychoice2" name="relevantSummary" value="2"></input>
                                        <label for="summarychoice2"> &nbsp;2&nbsp;</label>
                                        <input type="radio" id="summarychoice3" name="relevantSummary" value="3"></input>
                                        <label for="summarychoice3"> &nbsp;3&nbsp;</label>
                                        <input type="radio" id="summarychoice4" name="relevantSummary" value="4"></input>
                                        <label for="summarychoice4"> &nbsp;4&nbsp;</label>
                                        <input type="radio" id="summarychoice5" name="relevantSummary" value="5"></input>
                                        <label for="summarychoice5"> &nbsp;5&nbsp;</label>
                                    </span>
                                </div>}
                            <div onChange={onChangeAccuracyValue}>
                                <span class="sui-result__value">
                                    How accurate are the displayed results? (5 being the best)? &nbsp;
                                    <input type="radio" id="accuratechoice1" name="accurate" value="1"></input>
                                    <label for="accuratechoice1"> &nbsp;1&nbsp;</label>
                                    <input type="radio" id="accuratechoice2" name="accurate" value="2"></input>
                                    <label for="accuratechoice2"> &nbsp;2&nbsp;</label>
                                    <input type="radio" id="accuratechoice3" name="accurate" value="3"></input>
                                    <label for="accuratechoice3"> &nbsp;3&nbsp;</label>
                                    <input type="radio" id="accuratechoice4" name="accurate" value="4"></input>
                                    <label for="accuratechoice4"> &nbsp;4&nbsp;</label>
                                    <input type="radio" id="accuratechoice5" name="accurate" value="5"></input>
                                    <label for="accuratechoice5"> &nbsp;5&nbsp;</label>
                                </span>
                            </div>
                            <span class="sui-result__value">
                                <br></br> Please enter feedback for the results: <input class="sui-facet-search__text-input" type="text" ref={feedback} maxlength="1000"></input>
                            </span>
                            <br></br>
                            <br></br>
                            <Button class="sui-search-box__submit" type="submit" id="feedback" onClick={handleClick}>Submit</Button>
                        </span>
                    </form>
                </Popup>}
            </div>
            <ReactTooltip place="top" type="dark" effect="solid" />
        </li >
    )
};


const results = () => {
    return (
        <SearchProvider config={resultsconfig}>
            <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
                {({ wasSearched }) => {
                    return (
                        <div className="Results">
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>Mined Result Sections | ICDW</title>
                            </Helmet>
                            <ErrorBoundary>
                                <Layout
                                    header={
                                        <SearchBox
                                            searchAsYouType={true}
                                            inputProps={{ type: "Search", placeholder: "Search by paper title or search for author/journal/disease/drug/gene/miRNA/mutation/pathway/species" }}
                                            debounceLength={300}
                                        />
                                    }
                                    sideContent={
                                        <div>
                                            <ClearFilters />
                                            <br />
                                            <br />
                                            {wasSearched && <Sorting label={"Sort by"} sortOptions={SORT_OPTIONS} />}
                                            {/* <Facet field={"papertype.keyword"} label={"papertype"} /> */}
                                            <Facet field={"hassummary.keyword"} label={"Summary"} />
                                            <Facet field={"authors.keyword"} label={"authors"} />
                                            <Facet field={"journal.keyword"} label={"journal"} />
                                            <Facet field={"disease.keyword"} label={"disease"} />
                                            <Facet field={"drug.keyword"} label={"drug"} />
                                            <Facet field={"gene.keyword"} label={"gene"} />
                                            <Facet field={"mirna.keyword"} label={"mirna"} />
                                            <Facet field={"mutation.keyword"} label={"mutation"} />
                                            <Facet field={"pathway.keyword"} label={"pathway"} />
                                            <Facet field={"species.keyword"} label={"species"} />
                                        </div>
                                    }
                                    bodyContent={<Results resultView={CustomResultView} shouldTrackClickThrough={true} />}
                                    bodyHeader={
                                        <React.Fragment>
                                            {wasSearched && <PagingInfo />}
                                            {wasSearched && <ResultsPerPage />}
                                        </React.Fragment>
                                    }
                                    bodyFooter={<Paging />}
                                />
                            </ErrorBoundary>
                        </div>
                    );
                }}
            </WithSearch>
        </SearchProvider>
    );
};

export default results;