import { Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";


const home = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home | ICDW</title>
            </Helmet>
            <Card>
                <Card.Header></Card.Header>
                <Card.Body>
                    <Card.Title className="text-center">IIITH International COVID Data Warehouse - Beta version</Card.Title>
                    <Card.Text>
                        <h4> Frequently Asked Questions </h4>

                        <b>Q: What is International COVID Data Warehouse?</b>
                        <br></br>
                        A: ICDW is a project by IIIT Hyderabad to aggregate research papers pertaining to COVID-19 and coronaviruses in general and provide researchers and users with a platform to search through and query these papers using filters such as authors, journal, and license. Students working on the project have mined these research papers to extract result sections from them, allowing researchers and users to search through these result sections and query them using filters such as disease, drug, gene, miRNA, mutation, pathway, species, etc. The project is overseen by Prof. Kamalakar Karlapalem and Dr. Lini T Thomas. Students working on the project include Megha Agrawal, Surekha Medapati, Manvith Reddy, and Shanmukh Karra.
                        <br></br>
                        <br></br>

                        <b>Q: Where was the data for this project obtained?</b>
                        <br></br>
                        A: The data for this project has been obtained from <a href="https://www.kaggle.com/datasets/allen-institute-for-ai/CORD-19-research-challenge?select=metadata.csv"> CORD-19</a> (COVID-19 Open Research Dataset Challenge), which is a dataset created by the White House and a coalition of leading research groups.
                        <br></br>
                        <br></br>

                        <b>Q: What is the "Research Papers" section in the navigation bar?</b>
                        <br></br>
                        A: The "Research Papers" section contains papers from the aforementioned CORD-19 dataset. Most of these papers pertain to COVID-19 or coronaviruses in general. These papers can be searched through and queried upon using filters such as authors, journal, and license. They can also be sorted by publish date. A subset of these papers (around 20% of them) have filters in the form of extracted result sections such as disease, drug, gene, miRNA, mutation, pathway, and species.
                        <br></br>
                        <br></br>

                        <b>Q: What is the "Mined Result Sections of Research Papers" section in the navigation bar?</b>
                        <br></br>
                        A: The "Mined Result Sections of Research Papers" section contains result sections mined from research papers. Result sections extracted from the papers include fields such as disease, drug, gene, miRNA, mutation, pathway, species, etc. In addition, the papers have been summarized using six different techniques. Users can filter using the fields and view the different summaries.
                        <br></br>
                        <br></br>

                        <b>Q: Any known bugs?</b>
                        <br></br>
                        A: Please use the website on a desktop machine or laptop as some elements of the website may not be appropriately responsive on mobile screens. Also, initial load will take time, and query performance may be affected by multiple concurrent uses as the platform is currently hosted on a single machine. Lastly, many papers do not a have result section yet.
                        <br></br>

                    </Card.Text>
                    <Card.Text>
                        Please provide feedback <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=vDsaA3zPK06W7IZ1VVQKHLL9eZ2PrSJNmIUhBvJp8K9UNlRHSThCWjM4MENFVzMzSE0zREY1Q1BKTS4u">here.</a>
                    </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted"></Card.Footer>
            </Card>
        </div>
    );
};

export default home;